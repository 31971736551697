.header {
  background-color: #ffffff;
  transition: all 0.5s;
  box-shadow: 0px 2px 20px rgba(1, 41, 112, 0.1);
  z-index: 999;
}

.menu-toggle {
  background: none !important;
  border: none !important;
}
.user-logo {
  width: 120px;
}
.user-logo img {
  width: 100%;
}
.user-id {
  font-size: 28px;
}
.company-select {
  cursor: pointer;
}
.switch-company > .css-13cymwt-control {
  background: #e0e0e0 !important;
  border-radius: 10px !important;
  border: none !important;
}
/* Header.css */

/* Shimmer effect styles */
.loading-shimmer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.5);
  z-index: 1000;
}

.shimmer-placeholder {
  width: 300px;
  height: 50px;
  background: linear-gradient(90deg, #f3f3f3 25%, #e0e0e0 50%, #f3f3f3 75%);
  background-size: 200% 100%;
  animation: shimmer 1.5s infinite;
}

/* Animation for the shimmer effect */
@keyframes shimmer {
  0% {
    background-position: 200% 0;
  }
  100% {
    background-position: -200% 0;
  }
}
