.form-icon {
    position: relative;
  }
  
  .form-cntrl {
    padding: 0.375rem 1rem !important;
    outline: none !important;
    border: 1px solid #b8c8f8 !important;
    border-radius: 8px;
    background: #e5e8f1;
  }
  
  .prefix-icon {
    position: absolute;
    top: 4px;
    left: 10px;
  }
  
  .suffix-icon {
    position: absolute;
    right: 10px;
    top: 4px;
  }
  
  .form-control-padboth {
    padding: 0.375rem 2.2rem !important;
  }
  
  .form-control-padleft {
    padding: 0.375rem 0 0.375rem 2.2rem !important;
  }
  
  .form-control-padright {
    padding: 0.375rem 2.2rem 0.375rem 1rem !important;
  }
  .css-13cymwt-control {
    outline: 0 !important;
    background: #e5e8f1 !important;
    border: 1px solid #b8c8f8 !important;
    border-radius: 4px !important;
    /* width: 250px !important; */
  }