li {
  list-style: none !important;
}
ul {
  padding: 0 !important;
  margin: 0 !important;
}
.side-bar {
  position: fixed;
  top: 72px;
  left: 0;
  bottom: 0;
  overflow-y: auto;
  scrollbar-width: thin;
  background: #fff;
  transition: 0.5s all;
  z-index: 998;
  box-shadow: 0px 2px 20px rgba(59, 59, 59, 0.1);
  width: 230px;
  padding: 14px 0;
}
.toggle-sidebar .side-bar {
  left: 0px;
}
.toggle-sidebar .side-bar {
  left: -300px;
}
#side-bar .list-group ul li {
  padding: 1px 14px;
  width: 100%;
  position: relative;
}
#side-bar .list-group ul li a,
.list-group ul li a,
.sub-menu {
  border-radius: 5px;
  color: rgb(62, 62, 62) !important;
  transition: 0.25s all;
  width: 100% !important;
  border-radius: 8px;
  transition: all 0.4s;
  padding: 8px 12px;
  width: 100%;
  overflow: hidden;
  display: inline-flex;
  gap: 13px;
  font-size: 14px;
  cursor: pointer;
}
#side-bar .list-group li > .nav-link:hover,
#side-bar .list-group li > .sub-menu:hover,
#side-bar .list-group li .active {
  background-color: #da00372b;
  color: #da0037 !important;
  width: 100%;
}
#side-bar .list-group .li div.list-icon {
  height: 24px;
  width: 24px;
}

#side-bar.list-group .li .sub-menu.list-icon svg {
  font-size: 22px;
}
.list-icon {
  height: 24px;
  width: 24px;
}
.color {
  background-color: 265073;
}
.list-icon svg {
  font-size: 20px;
}
.arrow {
  position: absolute;
  right: 20px;
  transition: 0.3s;
}
.arrow.rotate {
  transform: rotate(90deg); /* Adjust the rotation angle as needed */
}
#main,
#footer {
  margin-left: 228px;
}
.toggle-sidebar #main,
.toggle-sidebar #footer {
  margin-left: 0px;
}
#main {
  margin-top: 60px;
  padding: 10px 8px;
  transition: 0.5s all;
}

.main {
  background-color: #ffffff;
  border-radius: 5px;
  padding: 20px;
}
